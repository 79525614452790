import React from 'react';
import './App.css';
import mark_of_the_loyal_sigil from './mark_of_the_loyal_sigil.png';
import { ThemeProvider } from '@emotion/react';
import createTheme from '@mui/material/styles/createTheme';
import { Typography } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#FF0000',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <div id="sigil" />
      </div>
    </ThemeProvider>
  );
}

export default App;

/*
<img src="hare.png" id="hare" alt="evil hare" />
  <CssBaseline />
  <h1>Grey h1 element</h1>
  <Button variant="text">Text</Button>
  <Button variant="contained">Contained</Button>
  <Button variant="outlined">Outlined</Button>
*/